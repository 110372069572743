<template>
  <div id="app">
    <router-link to="/about" id="logoLink">
      <img src="./assets/logo.svg" alt="OWL Media" id="logo" />
    </router-link>
    <div class="hr">
      <div></div>
    </div>
    <transition
      :name="transition"
      @after-leave="afterNav"
      @before-leave="scrollTop"
    >
      <router-view />
    </transition>
    <div :class="classes">
      <a :href="activeNav[0]" @click.prevent="hNav('left')"></a>
      <a :href="activeNav[1]" @click.prevent="hNav('right')"></a>
    </div>
    <div class="deco">
      <div class="screen main"></div>
      <div class="screen about">
        <div></div>
        <div></div>
        <div></div>
      </div>
      <div class="screen client">
        <div></div>
        <div></div>
      </div>
      <div class="screen digital">
        <div></div>
        <div></div>
        <div></div>
      </div>
      <div class="screen media">
        <div></div>
      </div>
      <div class="screen pr">
        <div></div>
        <div></div>
        <div></div>
      </div>
      <div class="screen cases">
        <div></div>
        <div></div>
      </div>
      <div class="screen contacts">
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "App",
  data() {
    return {
      left: null,
      right: null,
      classes: ["navigator"],
      navs: {
        Main: ["/about", "/client"],
        Client: ["/about", "/digital"],
        Digital: ["/client", "/media"],
        Media: ["/digital", "/pr"],
        PR: ["/media", "/cases"],
        Cases: ["/pr", "/contacts"],
        Contacts: ["/cases", "/about"],
        About: ["/contacts", "/client"],
      },
      activeNav: ["/", "/"],
      transition: "fade",
    };
  },
  mounted() {
    this.actualNav();
    this.$router.beforeEach((to, from, next) => {
      if (to.name === "Main") {
        this.classes.push("hide");
      } else {
        this.classes.splice(1);
      }
      this.activeNav = this.navs[to.name];
      next();
    });
  },
  methods: {
    actualNav() {
      const promise = new Promise((resolve) => {
        let name = this.$router.currentRoute.name;
        if (name) {
          resolve(name);
          return;
        }
        const ival = setInterval(() => {
          name = this.$router.currentRoute.name;
          if (name) {
            resolve(name);
            clearInterval(ival);
          }
        }, 30);
      });
      promise.then((name) => {
        this.activeNav = this.navs[name];
        if (name === "Main") {
          this.classes.push("hide");
        } else {
          this.classes.splice(1);
        }
      });
    },
    hNav(dir) {
      this.transition = "slide-" + dir;

      if (dir === "left") {
        this.$router.push(this.activeNav[0]);
      } else {
        this.$router.push(this.activeNav[1]);
      }
    },
    afterNav() {
      this.transition = "fade";
    },
    scrollTop() {
      window.scroll(0, 0);
    },
  },
};
</script>
