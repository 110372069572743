const nav = document.getElementById("nav");
const ringOuter = nav.getElementsByClassName("ringOuter")[0];
const burger = nav.getElementsByClassName("toggle")[0];

const hoverClasses = [
  "hover-cs",
  "hover-digital",
  "hover-media",
  "hover-pr",
  "hover-cases",
  "hover-contacts",
  "hover-about",
];

const links = [
  "/client",
  "/digital",
  "/media",
  "/pr",
  "/cases",
  "/contacts",
  "/about",
  "/",
];

const partsClasses = {
  Main: "main",
  Client: "client",
  Digital: "digital",
  PR: "pr",
  Media: "media",
  About: "about",
  Cases: "cases",
  Contacts: "contacts",
};
const flatPartsClasses = Object.values(partsClasses);

let oldPart = "Main";

const sectorSizes = [68.3, 113.3, 156, 211, 255.4, 313.2, 360];
const rotSize = 0.1;
let cursor = { x: null, y: null };
let boxCenter = { x: null, y: null };
let rotAngle = 0;
let rotate = true;

function windowSetup() {
  const rect = nav.getBoundingClientRect();
  boxCenter.x = rect.left + rect.width / 2;
  boxCenter.y = rect.top + rect.height / 2;
}

windowSetup();

window.addEventListener("resize", windowSetup);

nav.addEventListener("mousemove", (e) => {
  cursor.x = e.pageX;
  cursor.y = e.pageY;
  requestAnimationFrame(detectHover);
});

nav.addEventListener("click", (e) => {
  e.preventDefault();
  if (!nav.classList.contains("main")) {
    burger.click();
    return;
  }
  cursor.x = e.pageX;
  cursor.y = e.pageY;
  navCursorWrapper((angle) => {
    sectorSizes.some((size, i) => {
      if (angle < size) {
        window.router.push(links[i]);
        return true;
      }
      return false;
    });
  });
});

burger.addEventListener("click", (e) => {
  e.preventDefault();
  e.stopPropagation();
  if (!nav.classList.contains("main")) window.router.push("/");
  else if (typeof window.prev !== "undefined") window.router.push(window.prev);
});

function navCursorWrapper(hitCB, outCB) {
  let lengths = [cursor.x - boxCenter.x, cursor.y - boxCenter.y];
  let d = Math.sqrt(Math.pow(lengths[0], 2) + Math.pow(lengths[1], 2));
  if (d > nav.getBoundingClientRect().width / 2) {
    if (typeof outCB === "function") outCB();
    return;
  }
  let angle = Math.atan2(lengths[0], -lengths[1]) * (180 / Math.PI);
  if (angle < 0) angle = 180 + (180 + angle);
  angle -= rotAngle;
  if (angle < 0) angle += 360;
  hitCB(angle);
}

function detectHover() {
  navCursorWrapper((angle) => {
    rotate = false;
    let hClass;
    sectorSizes.some((size, i) => {
      if (angle < size) {
        hClass = hoverClasses[i];
        return true;
      }
      return false;
    });
    nav.classList.remove(...hoverClasses);
    if (hClass) {
      nav.classList.add(hClass);
    }
  }, leave);
}

function passiveRotation() {
  requestAnimationFrame(passiveRotation);
  if (!rotate) return;
  rotAngle += rotSize;
  if (rotAngle > 360) rotAngle -= 360;
  ringOuter.style.transform = "rotate(" + rotAngle + "deg)";
}

passiveRotation();

function leave() {
  if (nav.classList.contains("main")) rotate = true;
  nav.classList.remove(...hoverClasses);
  requestAnimationFrame(() => {
    nav.classList.remove(...hoverClasses);
  });
}

function goto(part) {
  if (!oldPart) oldPart = window.router.currentRoute.name;
  if (part === oldPart) return;
  let oldClass = partsClasses[oldPart];
  let newClass = partsClasses[part];
  if (oldClass === "main" || newClass === "main") {
    let moved = false;
    let cr = { x: 0, y: 0 };
    if (newClass !== "main") {
      nav.classList.remove("main");
      rotate = false;
      document.body.className = newClass;
    } else {
      document.body.className = "main";
      nav.classList.remove(...flatPartsClasses);
      rotate = true;
    }
    nav.classList.add(newClass);
    const updater = () => {
      windowSetup();
      window.navUpdate(cr.x, cr.y);
      if (!moved) requestAnimationFrame(updater);
    };
    const cursorChecker = (e) => {
      cr.x = e.pageX;
      cr.y = e.pageY;
    };
    window.addEventListener("mousemove", cursorChecker);
    setTimeout(() => {
      moved = true;
      window.removeEventListener("mousemove", cursorChecker);
    }, 800);
    requestAnimationFrame(updater);
  } else {
    nav.classList.remove(...flatPartsClasses);
    if (typeof newClass !== "undefined") {
      nav.classList.add(newClass);
      document.body.className = newClass;
    }
  }
  oldPart = part;
}

nav.addEventListener("mouseleave", leave);

window.nav = nav;

window.navUpdate = function (x, y) {
  cursor.x = x;
  cursor.y = y;
  requestAnimationFrame(detectHover);
};
window.navSetup = windowSetup;
window.navGoto = goto;
