const mobile = "//m.owlmedia.kz";

function redirect() {
  if (window.innerWidth / window.innerHeight <= 0.8) {
    window.location.href = mobile;
  }
}

redirect();
window.addEventListener("resize", redirect);
