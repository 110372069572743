import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/views/Home";

Vue.use(VueRouter);

// omit error on duplicate navigation
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

const routes = [
  {
    path: "/client",
    name: "Client",
    component: () => import(/* webpackChunkName: "client" */ "../views/Client"),
  },
  {
    path: "/digital",
    name: "Digital",
    component: () =>
      import(/* webpackChunkName: "client" */ "../views/Digital"),
  },
  {
    path: "/pr",
    name: "PR",
    component: () => import(/* webpackChunkName: "pr" */ "../views/PR"),
  },
  {
    path: "/media",
    name: "Media",
    component: () => import(/* webpackChunkName: "media" */ "../views/Media"),
  },
  {
    path: "/cases",
    name: "Cases",
    component: () => import(/* webpackChunkName: "pr" */ "../views/Cases"),
  },
  {
    path: "/contacts",
    name: "Contacts",
    component: () => import(/* webpackChunkName: "pr" */ "../views/Contacts"),
  },
  {
    path: "/about",
    name: "About",
    component: () => import(/* webpackChunkName: "about" */ "../views/About"),
  },
  {
    path: "*",
    name: "Main",
    component: Home,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  window.navGoto(to.name);
  window.prev = from;
  next();
});

export default router;
